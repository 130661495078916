<template>
  <div class="photo">
    <van-uploader
      v-model="fileList"
      multiple
      :after-read="afterRead"
      :max-count="1"
      preview-size="160px"
    />
    <div class="update" @click="back">Back</div>
  </div>
</template>

<script>
import { uploadimage, getoneorderinfo } from "@/api/start.js";
export default {
  name: "Photo",
  data() {
    return {
      fileList: [],
      id: null,
    };
  },
  methods: {
    afterRead(file) {
      console.log(file);
      file.status = "uploading";
      file.message = "upload...";
      uploadimage({
        id: this.id,
        image_file: file.content,
      }).then((res) => {
        if (res.code != 1) {
          file.status = "failed";
          file.message = "fail";
        } else {
          file.status = "susccess";
        }
      });
    },
    back() {
      this.$router.back();
    },
  },
  created() {
    // console.log(this.$route.query);
    this.id = this.$route.query.id;
    getoneorderinfo({
      id: this.id,
    }).then((res) => {
      console.log(res);
      if (res.code == 1) {
        if (res.data.attach_file) {
          let obj = {
            content: "http://massage02.ingtech.net.au" + res.data.attach_file,
            file: {
              type: "image/png",
            },
            url: "http://massage02.ingtech.net.au" + res.data.attach_file,
          };
          this.fileList.push(obj);
        }
      }
    });
  },
};
</script>

<style>
.photo {
  background: #fff;
  height: 100vh;
  padding: 16px;
}
.update {
  width: 80%;
  padding: 16px 0;
  border-radius: 16px;
  margin: 0 auto;
  margin-top: 48px;
  background: #087536;
  color: #fff;
  text-align: center;
}
.van-uploader {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}
</style>